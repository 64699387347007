// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
// import './plugins/chartist'
// import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import './resources/js/app'
import * as Sentry from '@sentry/vue'
import {BrowserTracing} from '@sentry/tracing'
import VueGtag from 'vue-gtag'


Vue.config.productionTip = false

Sentry.init({
  Vue,
  environment: process.env.NODE_ENV,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        traceOrigins: [
            'localhost',
            'tilyet.org'
        ],
        traceSampleRate: 1.0
      })
  ]
})

Vue.use(VueGtag,{
    config: {
        id: process.env.VUE_APP_GTAG_ID
    }
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
