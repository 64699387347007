import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'home',
      path: '/',
      component: () => import('@/views/pages/Login'),
      meta: {
        title: 'Account Login'
      }
    },
    {
      name: 'account',
      path: '/account/view',
      component: () => import('@/views/pages/ViewAccount'),
      meta: {
        title: 'My Account'
      }
    },
    {
      name: 'update_account',
      path: '/account/update',
      component: () => import('@/views/pages/UpdateAccount'),
      meta: {
        title: 'Update Account'
      }
    },
    {
      name: 'update_password',
      path: '/account/password',
      component: () => import('@/views/pages/UpdatePassword'),
      meta: {
        title: 'Update Password'
      }
    },
    {
      name: 'reset_password',
      path: '/resetpassword',
      component: () => import('@/views/pages/ResetPassword'),
      meta: {
        title: 'Reset Password'
      }
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('@/views/pages/Login'),
      meta: {
        title: 'Account Login'
      }
    },
    {
      name: 'resources',
      path: '/resources',
      component: () => import('@/views/pages/Resources'),
      meta: {
        title: 'Links and Resources'
      }
    },
    {
      name: 'logout',
      path: '/logout',
      component: () => import('@/views/pages/Logout'),
      meta: {
        title: 'Account Logout'
      }
    },
    {
      name: 'enroll',
      path: '/enroll',
      component: () => import('@/views/pages/Register'),
      meta: {
        title: 'Enroll'
      }
    },
    {
      name: 'add_dependent',
      path: '/dependent',
      component: () => import('@/views/pages/Dependent'),
      meta: {
        title: 'Add/Edit Dependent'
      }
    },
    {
      name: 'edit_dependent',
      path: '/dependent/:id',
      component: () => import('@/views/pages/Dependent'),
      meta: {
        title: 'Edit Dependent'
      }
    },
    {
      name: 'view_dependents',
      path: '/dependents/view',
      component: () => import('@/views/pages/ViewDependents'),
      meta: {
        title: 'View Dependents'
      }
    },

    {
      name: 'add_claim',
      path: '/claim',
      component: () => import('@/views/pages/Claim'),
      meta: {
        title: 'Submit Claim'
      }
    },
    {
      name: 'edit_claim',
      path: '/claim/:id',
      component: () => import('@/views/pages/Claim'),
      meta: {
        title: 'Edit Claim'
      }
    },
    {
      name: 'view_claims',
      path: '/claims/view',
      component: () => import('@/views/pages/ViewClaims'),
      meta: {
        title: 'View Claims'
      }
    },
    {
      name: 'view_invoices',
      path: '/invoices/view',
      component: () => import('@/views/pages/ViewInvoices'),
      meta: {
        title: 'View Invoices'
      }
    },
    {
      name: 'view_invoice',
      path: '/invoice/:id',
      component: () => import('@/views/pages/ViewInvoice'),
      meta: {
        title: 'View Invoice'
      }
    },
    {
      name: 'add_edit_payment',
      path: '/invoice/:invoiceId/payment/:paymentId?',
      component: () => import('@/views/pages/Payment'),
      meta: {
        title: 'Make Payment'
      }
    },
    {
      name: 'payment_information',
      path: '/payment',
      component: () => import('@/views/pages/PaymentInformation'),
      meta: {
        title: 'Payment Information'
      }
    },
    {
      name: 'error',
      path: '/error',
      component: () => import('@/views/pages/Error'),
      meta: {
        title: 'Error'
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
  })
  next()
})

export default router
